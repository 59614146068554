import { inject, Injectable } from '@angular/core';
import { RefreshTokenInterceptor } from '@wndr/common/core/interceptors/refresh-token.interceptor';
import { ModalService } from '@wndr/common/core/services/modal.service';
import { RedirectService } from '@wndr/common/core/services/redirect.service';
import { UserService } from '@wndr/common/core/services/user.service';
import { Observable, tap } from 'rxjs';

/**
 * Refresh token interceptor for the web platform.
 * Catches requests with outdated tokens and logout from the app.
 */
@Injectable()
export class WebRefreshTokenInterceptor extends RefreshTokenInterceptor {
	private readonly userService = inject(UserService);

	private readonly redirectsService = inject(RedirectService);

	private readonly modalService = inject(ModalService);

	/** @inheritdoc */
	protected override readonly isShouldRepeatRequest = false;

	/** @inheritdoc */
	protected override initializeRefreshSecretRequest(): Observable<void> {
		return this.userService.logout().pipe(
			tap(() => {
				this.modalService.closeAll();
				this.redirectsService.redirectToAuthPage();
			}),
		);
	}
}

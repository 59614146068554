import { HttpErrorResponse, HttpEvent, HttpHandler, HttpInterceptor, HttpRequest, HttpStatusCode } from '@angular/common/http';
import { Injectable, inject } from '@angular/core';
import { EMPTY, Observable } from 'rxjs';
import { filter, shareReplay, switchMap, tap } from 'rxjs/operators';

import { AppUrlsConfig } from '../services/app-urls.config';
import { catchHttpErrorResponse } from '../utils/rxjs/catch-http-error-response';

/** Refresh token result. */
export enum RefreshTokenResult {
	Unverified = 'unverified',
	NonConfigured = 'non-configured',
}

/** Catches requests with outdated tokens and attempts to refresh it and then retry the request. */
@Injectable()
export abstract class RefreshTokenInterceptor implements HttpInterceptor {

	/** Active request for token refresh. */
	private refreshSecretRequest$: Observable<void | RefreshTokenResult> | null = null;

	private readonly apiUrlsConfig = inject(AppUrlsConfig);

	/** Refresh secret request initializer. */
	protected abstract initializeRefreshSecretRequest(): Observable<void | RefreshTokenResult>;

	/** Whether failed requests should be repeated after token refresh. */
	protected abstract readonly isShouldRepeatRequest: boolean;

	/** @inheritdoc */
	public intercept(
		req: HttpRequest<unknown>,
		next: HttpHandler,
	): Observable<HttpEvent<unknown>> {
		if (!this.shouldRefreshTokenForUrl(req.url)) {
			return next.handle(req);
		}

		return next.handle(req).pipe(
			catchHttpErrorResponse(error => {
				if (this.shouldHttpErrorBeIgnored(error)) {
					throw error;
				}

				this.refreshSecretRequest$ ??= this.initializeRefreshSecretRequest().pipe(
					shareReplay({ refCount: true, bufferSize: 1 }),
				);

				return this.refreshSecretRequest$.pipe(
					tap(() => {
						this.refreshSecretRequest$ = null;
					}),
					filter(result => result === undefined),
					switchMap(() => this.isShouldRepeatRequest ? next.handle(req) : EMPTY),
				);
			}),
		);
	}

	private shouldHttpErrorBeIgnored(error: HttpErrorResponse): boolean {
		return error.status !== HttpStatusCode.Unauthorized;
	}

	private shouldRefreshTokenForUrl(url: string): boolean {
		return this.apiUrlsConfig.isApplicationUrl(url) &&
      !this.apiUrlsConfig.isAuthUrl(url);
	}
}
